<template>
<div>
  <div class="container-fluid header-sub " style="">
    <div class="container-standar h-100 "><div class="breadcumb-container"></div></div>
  </div>
  <div> 
    <div class="cotainer-standar text-center">
      <div class="dk-container">
        <h1 class="d-block font-30 text-white font-weight-6 mb-40">BLOG REAL TRAVEL</h1>
        <div class="container-standar mt-10 mb-10 pad-20 card shadow">
          <div class="row justify-center">
             
 
            <div class= "width-25 w-res-tab-50 w-res-tab-100 pad-res-20 mb-res-10 mb-20 cursor-pointer" v-for="(items, i) in posts" :key="i">
              <figure class="snip1253" @click="onSumbit(items.slug)">
                <template v-if="items.type == 'artikel' ">
                  <div class="image">
                    <img v-bind:src="items.file" :alt="items.slug"/>
                  </div>
                </template>
                <template v-if="items.type == 'youtube' ">
                  <!--<div class="image">
                    <img v-bind:src="items.file" alt="sample52" />
                  </div>-->
                  <iframe class="w-100" height="230" :src="'https://www.youtube.com/embed/'+contentstr(items.file)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
                <figcaption>
                  <div class="date">
                    <span class="day">{{tanggal(items.date)}}</span>
                    <span class="month">{{bulan(items.date)}}</span>
                  </div>
                  <h3>{{items.title}}</h3>
                  <!--<p>Memperkenalkan Langkah Langkah Bergabung Sebagai Agent .....</p>-->
                  <div v-html="content(items.content)"></div>
                </figcaption>
              </figure>
            </div> 

            <div class="col-md-12 text-center mt-10" v-show="btnshow">
              <span @click="getshowmore()"
                class="bg-primary pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-white margin-auto width-30 text-center d-block cursor-pointer font-20 border-radius-5"
              >Show More</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import * as moment from 'moment' 
import stripHtml from "string-strip-html";
export default {
  data() {
    return {
        posts: [],
          err: [],
          datapagging:12,
          btnshow:true,
          dataLength:null,
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.getData();
    this.getMaxPag();
  },
  methods: {
     getData(){  
        this.axios.get('https://api.realtravel.co.id/content/news?length='+ this.datapagging)
        .then( response => {
          let postsa = response.data.response.data
          this.posts = postsa
        //  postsa.length = this.dataLength
        }).catch( e =>{
          // console.log(e);
          this.err.push(e)
        });
        // console.log();
    },
    getMaxPag(){
       this.axios.get('https://api.realtravel.co.id/content/news?length=all')
          .then( response => {
              let datalength = response.data.response.data
              this.dataLength =  datalength.length
              // console.log(this.dataLength)
            })
    },
    getshowmore(){
      if(this.dataLength <= this.datapagging){
          this.btnshow = false
      }else{
        this.datapagging = this.datapagging*2
         if(this.dataLength <= this.datapagging){
          this.btnshow = false
         }
        this.getData()
      
      }
    },
    tanggal(val){
      return moment(val).format('DD')
    },
    bulan(val){
      return moment(val).format('MMM')
    },
    content(val){
      return stripHtml(val).substring(0, 80)+'...';
    },
     contentstr(val){
      return val.substr(32)
    },
    onSumbit(slug){
      this.$router.push('/berita/detail/'+slug);
    }
  },
};
</script>